
export default {
  name: "seccion-categoria",
  props: {
    tipo: {
      type: String,
      default: "categoria",
    },
    tituloSeccion: { type: String, default: "Categoría" },
    categoriasSubcategorias: {
      type: Array,
      required: true,
    },
    mostrarAgregar: { type: Boolean, default: true },
    campoNombre: { type: String, default: "nombre" },
  },
  computed: {},
  data() {
    return {
      modalAgregar: false,
      itemSeleccionado: 0,
    };
  },
  watch: {
    categoriasSubcategorias() {
      this.itemSeleccionado = 0;
    },
  },
  methods: {
    esCategoria(categoriaSubcategoria) {
      return categoriaSubcategoria.categoria_padre_id == null;
    },
    seleccionarItem(index, categoriaSubcategoria) {
      this.itemSeleccionado = index;
      this.$emit("seleccionar-categoria-subcategoria", categoriaSubcategoria);
    },
    agregar() {
      this.$emit("agregar");
    },
    editar(categoriaSubcategoria) {
      this.$emit("editar", categoriaSubcategoria);
    },
    eliminar(categoriaSubcategoria) {
      this.$emit("eliminar", categoriaSubcategoria);
    },
  },
};
